.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
.login-logo {
    width: 350px;
    height: auto;
    margin-top: 18vh;
}

/* 移除所有 tab 的下划线 */
.login-container .ant-tabs .ant-tabs-nav::before {
  border: none;
}
.login-container .ant-tabs-tab {
    margin: 0 50px !important;
}
.login-form{
    margin: auto;
    margin-top: 20px;
}
