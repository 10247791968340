.attendance-calendar-wrapper {
    .ant-picker-calendar {
        background: white;
    }

    /* Hide dates not in the current month */
    .ant-picker-cell:not(.ant-picker-cell-in-view) {
        visibility: hidden;
    }

    /* Hide year/month switch buttons */
    .ant-picker-calendar-mode-switch {
        display: none !important;
    }
}

.anchor-container {
    padding: 24px;
    background: white;
    min-height: 100%;
} 