.anchor-container {
    padding: 24px;
    background: #fff;
    height: calc(100vh - 120px);
}

.anchor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.anchor-header h2 {
    margin: 0;
    font-size: 19px;
}

.search-form {
    background: #fafafa;
    padding: 24px;
    border-radius: 4px;
    margin-bottom: 24px;
}

.search-form .ant-form-item {
    margin-bottom: 16px;
}

/* Table styles */
.ant-table-wrapper {
    margin-top: 16px;
}

.ant-table-thead>tr>th {
    background: #fafafa;
    font-weight: 500;
}

/* Modal styles */
.ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
}

.ant-modal-footer {
    border-top: 1px solid #f0f0f0;
}

/* Form styles */
.ant-form-item-label>label {
    color: rgba(0, 0, 0, 0.85);
}

.ant-input-number-handler-wrap {
    display: none;
}

/* Button styles */
.ant-btn-link {
    padding: 4px 8px;
}

.ant-btn-link:hover {
    background: rgba(0, 0, 0, 0.02);
}

/* Responsive styles */
@media (max-width: 768px) {
    .search-form .ant-form-item {
        margin-right: 0 !important;
        width: 100%;
    }

    .search-form .ant-form-item-control-input {
        width: 100%;
    }

    .search-form .ant-picker {
        width: 100% !important;
    }

    .search-form .ant-select {
        width: 100% !important;
    }

    .search-form .ant-input {
        width: 100% !important;
    }
}