.dashboard 
  min-height: 100%
  padding: 15px 20px
  background-color: #ffffff
  .dashboard-title
    font-size: 24px
    font-weight: bold
  .dashboard-header
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    align-items: center
    margin-bottom: 20px
    .dashboard-header-left
      display: flex
      align-items: center

.dashboard-filters 
  display: flex
  align-items: center
