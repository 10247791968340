.live-broadcast-details {
  background: #eee;
  .filters {
    margin-bottom: 10px;
    background-color: #ffffff;
    padding: 20px;
  }

  .shop-details {
    padding: 20px 40px;
    background-color: #ffffff;
    .shop-details-header {
      display: flex;
      margin-bottom: 20px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #ddd;
      }
      .shop-details-header-right {
        margin-left: 20px;
        h3 {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
        }
        p {
          margin-top: 10px;
          margin-bottom: 0;
          span {
            margin-right: 30px;
          }
        }
      }
    }
    .shop-details-content {
      margin-top: 25px;
      ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          .data-title {
            font-size: 14px;
            font-weight: 400;
          }
          .data-value {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .main-data-module {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
    & > h2 {
      margin: 0;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .live-view-type-btn {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 10px 20px;
        border: 1px solid #ddd;
        cursor: pointer;
        &:hover {
          background-color: #1677ff;
          color: #ffffff;
        }
        &.active {
          background-color: #1890ff;
          color: white;
          border-color: #1890ff;
        }
      }
    }
  }

  .statistics {
    margin-bottom: 20px;
  }

  .chart-container {
    margin-bottom: 20px;

    .chart-filters {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .detailed-metrics {
    overflow-x: auto;

    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
}
.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 5px;
}
.ant-table-wrapper .ant-table-thead >tr>th {
  padding: 6px;
}