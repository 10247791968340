.anchor-analysis {
  padding: 20px;
  background-color: #fff;
  min-height: calc(100vh - 100px);

  .filters {
    margin-bottom: 20px;
  }

  .echarts-for-react {
    height: 600px;
  }
}

.schedule-grid-container {
  margin-top: 20px;
  height: calc(100vh - 260px);
  position: relative;
  border: none;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 60px;
    height: 55px;
    background: #fff;
    z-index: 999;
    pointer-events: none;
  }
  
  .date-header {
    height: 60px;
    position: absolute;
    top: 0;
    left: 60px;
    right: 0;
    background: #fff;
    z-index: 3;
    overflow: hidden;
    
    .date-header-content {
      display: flex;
      width: fit-content;
      min-width: 100%;
      
      .date-column {
        width: var(--column-width, 120px);
        flex: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 0;
        box-sizing: border-box;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 10px;
          width: calc(100% - 20px);
          height: 2px;
          background-color: #e8e8e8;
        }
        
        .date-text {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          
          .weekday {
            font-size: 12px;
            color: #666;
          }
        }
        .add-schedule {
          margin: 0 auto;
          margin-top: 3px;
        }
      }
    }
  }

  .schedule-content {
    height: 100%;
    display: flex;
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    padding-top: 60px;
    
    .time-axis {
      position: sticky;
      left: 0;
      top: 60px;
      width: 60px;
      flex-shrink: 0;
      background: #fff;
      border-right: 1px solid #e8e8e8;
      height: fit-content;
      
      .time-labels {
        height: 1440px;
        position: relative;
        background-color: #fff;
        
        .time-label {
          height: 30px;
          display: flex;
          align-items: center;
          position: relative;
          box-sizing: border-box;
          padding-right: 5px;
          
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 1px;
            background: #e8e8e8;
          }
          
          &[data-hour-type="full"] {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 15px;
              height: 1px;
              background: #e8e8e8;
            }
            
            .time-text {
              font-weight: 500;
              margin-right: auto;
              padding-left: 5px;
              margin-top: -30px;
            }
          }
          
          &[data-hour-type="half"] {
            &::after {
              width: 5px;
            }
          }
        }
      }
    }

    .schedule-main {
      flex: 1;
      min-width: fit-content;
      position: relative;
      height: 1440px;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-image: linear-gradient(#e8e8e8 1px, transparent 1px);
        background-size: 100% 60px;
        pointer-events: none;
        z-index: 1;
      }

      .grid-content {
        position: relative;
        z-index: 2;
        display: flex;
        width: fit-content;
        min-width: 100%;
        height: 100%;
        
        .date-column {
          width: var(--column-width, 120px);
          flex: none;
          position: relative;
          box-sizing: border-box;
          padding: 0;
          
          &:not(:last-child) {
            border-right: 1px solid #e8e8e8;
          }
          
          .time-block {
            height: 30px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.date-column {
  position: relative;
  
  .schedule-block {
    position: absolute;
    left: 1px;
    background: var(--schedule-block-color, #f0f0f0);
    padding: 4px;
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &.live {
      filter: brightness(0.95);
    }
    
    &:hover {
      z-index: 1;
      filter: brightness(1.05);
      box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    }
    
    .anchor-name {
      border-radius: 12px;
      border: 1px solid #666;
      width: 50px; 
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 0.3s;
    }
  }
}

.anchor-item {
  padding: 8px 15px;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;

  &:hover {
    background-color: rgba(24, 144, 255, 0.1);
  }

  &.selected {
    background-color: rgba(24, 144, 255, 0.1);
    border: 1px solid #1890ff;
    
    &::before {
      content: '✓';
      position: absolute;
      top: -1px;
      right: 1px;
      font-size: 10px;
      color: #fff;
      z-index: 2;
      transform: scale(0.8);
    }
    
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 20px 0;
      border-color: transparent #1890ff transparent transparent;
    }
  }
}

.group-row {
  background-color: #fafafa;
  font-weight: bold;
  
  td {
    padding: 12px 8px !important;
  }
}