.add-user-modal {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    width: calc(100% - 20px);
    padding-right: 20px;
}
.user-management {
    min-height: 100%;
    padding: 15px 20px; 
    background-color: #ffffff;
}