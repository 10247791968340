@charset "UTF-8";
.anchor-analysis {
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);
}

.anchor-analysis .filters {
  margin-bottom: 20px;
}

.anchor-item {
  padding: 8px 15px;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;
}

.anchor-item:hover {
  background-color: rgba(24, 144, 255, 0.1);
}

.anchor-item.selected {
  background-color: rgba(24, 144, 255, 0.1);
  border: 1px solid #1890ff;
}

.anchor-item.selected::before {
  content: '✓';
  position: absolute;
  top: -1px;
  right: 1px;
  font-size: 10px;
  color: #fff;
  z-index: 2;
  transform: scale(0.8);
}

.anchor-item.selected::after {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #1890ff transparent transparent;
}

.group-row {
  background-color: #fafafa;
  font-weight: bold;
}

.group-row td {
  padding: 12px 8px !important;
}
