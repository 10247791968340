.slide-menu 
  height: 100vh
  box-sizing: border-box
  padding-top: 10px
  .logo 
    height: 40px
    margin: auto
    display: block
    margin-bottom: 10px



